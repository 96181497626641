import React from 'react'
import './Profile.css'
import Img from '../../../../imgs/Profile/andrio.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faAt, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

const Profile = () => {
    return (
        <div className="Profile">
            <div className="container">
                <div className="box">
                    <img src={Img} alt="" />
                </div>
                <h1><span>Andrio</span> Rodrigues</h1>
                <h4>CEO</h4>
                <div className="social">
                    <a href="https://www.ateampdr.com/" target="_blank" rel="noopener noreferrer">
                        <span>
                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                        </span>
                    </a>
                    <a href="mailto:andrio@ateampdr.com" target="_blank" rel="noopener noreferrer">
                        <span>
                            <FontAwesomeIcon icon={faAt} className='icon' />
                        </span>
                    </a>
                    <a href="https://www.facebook.com/ateampdr" target="_blank" rel="noopener noreferrer">
                        <span>
                            <FontAwesomeIcon icon={faFacebookF} />
                        </span>
                    </a>
                    {/* <a href="https://www.instagram.com/andrio_rodrigues/" target="_blank" rel="noopener noreferrer">
                        <span>
                            <FontAwesomeIcon icon={faTwitter} />
                        </span>
                    </a> */}
                    <a href="https://www.instagram.com/ateampdr/" target="_blank" rel="noopener noreferrer">
                        <span>
                            <FontAwesomeIcon icon={faInstagram} />
                        </span>
                    </a>
                    <a href="tel:+15128251467" target="_blank" rel="noopener noreferrer">
                        <span>
                            <FontAwesomeIcon icon={faPhone} />
                        </span>
                    </a>
                    {/* <a href="https://www.instagram.com/andrio_rodrigues/" target="_blank" rel="noopener noreferrer">
                        <span>
                            <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                    </a> */}
                </div>
            </div >
        </div >
    )
}

export default Profile