import React from 'react'
import './Welcome.css'

const Welcome = () => {

    return (
        <div className='Welcome'>
            <div className="container">
                <h1>Welcome to the<br /> A-Team Group website</h1>
                <h4>Our talented professionals bring unique expertise and perspectives to each project. United in our mission to innovate and make a positive impact, we collaborate and leverage our collective strengths for greater success.</h4>
            </div>
        </div>
    )
}

export default Welcome