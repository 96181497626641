import './App.css'
import Home from './pages/Home/Home'

function App() {
    return (
        <div className="App">
            <div className='container'>
                <main className="Content">
                    <Home />
                </main>
            </div>
        </div>
    )
}

export default App
