import React from 'react'
import Top from './components/Top/Top'
import Profile from './components/Profile/Profile'
import Welcome from './components/Welcome/Welcome'
import EmbededVideo from './components/Video/EmbededVideo'
import Services from './components/Services/Services'
import ContactForm from './components/ContactForm/ContactForm'
import Choose from './components/Choose/Choose'

const Home = () => {

    return (
        <div className='Home'>
            <Top />
            <Profile />
            <EmbededVideo />
            <Welcome />
            <Choose />
            <Services />
            <ContactForm />
        </div>
    )
}

export default Home