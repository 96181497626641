import React from 'react'
import './Top.css'
import Logo from '../../../../imgs/logo.png'

const Top = () => {
    return (
        <div className='Top'>
            <div className="services-home">
                <div className="box">
                    <img src={Logo} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Top