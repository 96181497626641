import React, { useState } from 'react'
import './ContactForm.css'
import Img1 from '../../../../imgs/whyus1.jpg'
import IconMail from '../../../../imgs/iconmail.png'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'

const ContactForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const sendEmail = async(e) => {
        e.preventDefault()
        console.log(name, email, subject, message)
        if (!name || !email || !subject || !message) {
            return toast.error('Please, fill email, subject, name and message.')
        }
        try {
            setLoading(true)
            const { data } = await axios.post(`/api/email`, {
                name, email, subject, message
            })
            setLoading(false)
            toast.success(data.message)
        } catch (err) {
            setLoading(false)
            toast.error(err.response && err.response.data.message
                ? err.response.data.message
                : err.message
            )
        }
    }

    return(
        <div className='ContactForm'>
            <ToastContainer position='bottom-center' limit={1} />
            <form>
                <h3>Contact us</h3>
                <input type="text" placeholder='Your name' name='name' onChange={(e) => setName(e.target.value)} />
                <input type="text" placeholder='Email address' name='name' onChange={(e) => setEmail(e.target.value)} />
                <input type="text" placeholder='Subject' name='name' onChange={(e) => setSubject(e.target.value)} />
                <textarea name="message" id="" cols="30" rows="2" placeholder='Message' onChange={(e) => setMessage(e.target.value)}></textarea>
                <input disabled={loading} onClick={sendEmail} className='submitButton' type="submit" value={loading ? 'SENDING...' : 'SEND MESSAGE'} />
            </form>
            <div className="contactinfo">
                <div className="infor">
                    <h3>Or if you prefer, give us a call</h3>
                    <h2>(800) 257-0131</h2>
                </div>
                <img src={Img1} alt="" />
            </div>
            <div className="centers">
                <div className="box box1">
                    <img src={IconMail} alt="" />
                    <span>OKLAHOMA  - OKC<br />A-Team Collision Center</span>
                    <span><strong>oklahoma</strong>@ateamcollision.com</span>
                </div>
                <div className="box box2">
                    <img src={IconMail} alt="" />
                    <span>Carrolton - TX<br />A-Team Collision Center</span>
                    <span><strong>carrolton</strong>@ateamcollision.com</span>
                </div>
            </div>
        </div>
    )
}

export default ContactForm