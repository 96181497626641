import React from "react";
import ReactPlayer from "react-player";
import './Video.css'
import File from '../../../../imgs/file.mp4'
import IconPlay from '../../../../imgs/play.png'
import Thumb from '../../../../imgs/fundovideohome.jpg'

const Video = () => (
    <div className="video-responsive">
        <ReactPlayer
            url={File}
            width="100%"
            height="100%"
            playing
            playsinline
            controls={true}
            loop={false}
            light={Thumb}
            muted
            playIcon={<div className="play-icon">
                <img src={IconPlay} alt="Play" />
                <h1 className="bt-watch">Watch the video</h1>
            </div>
            }
        />
    </div>
);

export default Video;
