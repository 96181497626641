import React from 'react'
import './Choose.css'

const Choose = () => {

    return (
        <div className='Choose'>
            <div className="image">
                <div className="img-choose" />
                <div className="img-choose-mob" />
                <div className="img-choose-mob2" />
            </div>
        </div >
    )
}

export default Choose