import React from 'react'
import './Services.css'
import Img from '../../../../imgs/fundoservicoshome.png'
import Img1 from '../../../../imgs/logoservicehome1.png'
import Img2 from '../../../../imgs/logoservicehome2.png'
import Img3 from '../../../../imgs/logoservicehome3.png'

const Services = (props) => {
    return(
        <div className='Services'>
            {!props.noicons && <h1>Our Services</h1>}
            <h2>The PREMIERE bodyshop for your car</h2>
            <img width="100%" src={Img} alt="" />
            {!props.noicons && <div className="services-home">
                <div className="box">
                    <img src={Img1} alt="" />
                    <a href="/collision">Learn more</a>
                </div>
                <div className="box">
                    <img src={Img2} alt="" />
                    <a href="/autocare">Learn more</a>
                </div>
                <div className="box">
                    <img src={Img3} alt="" />
                    <a href="/fleet">Learn more</a>
                </div>
            </div>}
        </div>
    )
}

export default Services