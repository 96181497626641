import React from "react"
import "./Video.css"
import Video from "./Video"

export default function EmbededVideo() {

    return (
        <div className="Video">
            <h1>About us</h1>
            <Video />
        </div>
    )
}
